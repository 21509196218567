
.contain_box {
  height: 100%;

  .goods-info {
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 60px;

    .info-left {
      height: 100px;
      cursor: pointer;

      img {
        max-height: 100%;
      }
    }

    .info-right {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .right-item {
        margin: 20px;
      }
    }
  }

  .cancel_margin {
    padding: 0px;
    margin: 0;
  }

  .bold_font {
    font-weight: bold;
    font-size: 25px;
  }

  .center {
    margin: auto;
    text-align: center;
  }

  .data-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    text-align: center;

    .item {
      cursor: pointer;
      width: 50px;
      height: 30px;
      background: #f1f0fe;
      display: flex;
      justify-content: center;
      align-items: center;

      &.left {
        border-radius: 5px 0px 0px 5px;
      }

      &.right {
        border-radius: 0px 5px 5px 0px;
      }
    }

    .selected {
      color: #ffffff;
      background: #564ced;
    }
  }
}

.data-trend {
  margin-top: 20px;

  .data-menu-box {
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
  }

  ::v-deep.tooltip {
    height: 59px;
    border-radius: 2px;
    box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
    background: #fff;

    .tooltip-box {
      padding: 13px 20px 10px;
    }

    .tooltip-title {
      font-size: 12px;
      color: #999999;
    }

    .tooltip-title-right {
      float: right;
      padding-right: 10px;
      font-size: 16px;

      &.blue {
        color: #564ced;
      }
    }

    .tooltip-title-left {
      color: #333;
    }
  }
}

.no-data {
  height: 20px;
  margin: 20px 0;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
}
