
.favorite {
    height: 100%;
    display: flex;

    .leftBox {
        width: 80%;
        display: flex;
        flex-direction: column;

        .DsVideoDetail-header {
            position: relative;
            display: flex;
            background: #ffffff;
            padding: 20px 24px;

            .headerCover {
                width: 105px;
                height: 130px;
                background: #f2f2f2;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .headerCountent {
                width: 1%;
                flex: 1;
                overflow: hidden;
                margin-left: 26px;

                .headerCountentTitle {
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #333333;
                    font-size: 16px;
                    width: 432px;
                    height: 44px;
                    margin: 0;
                }

                .headerCountentLike {
                    margin-top: 8px;
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 20px;
                        color: #333333;
                        line-height: 21px;
                    }

                    span {
                        color: #333333;
                        font-size: 16px;
                        font-weight: 400;
                        margin-left: 6px;

                    }
                }

                .headerCountenHot {
                    overflow: hidden;

                    span {
                        margin-top: 12px;
                    }

                    span:nth-child(1) {
                        color: #999999;
                        font-size: 12px;
                    }

                    .hotWords {
                        display: inline-block;
                        color: #333333;
                        font-size: 12px;
                        padding: 6px 16px;
                        background: #F4F4F4;
                        border-radius: 12px;
                        line-height: 1;
                        font-weight: 400;
                        margin-left: 15px;
                    }
                }

                .issueTime {
                    color: #999999;
                    font-size: 12px;
                    line-height: 1;
                    margin-top: 10px;
                    margin-bottom: 0;
                }
            }

            .headerBtn {
                width: 106px;
                height: 32px;
                background: linear-gradient(85deg, #FF0000, #FF6060,);
                border-radius: 2px;
                color: #ffffff;
                text-align: center;
                line-height: 32px;
                font-size: 14px;
                position: absolute;
                right: 2%;
                top: 9%;
            }
        }

        .tab_box {
            display: flex;
            flex: 1;
            height: 1%;
            margin-top: 20px;
            position: relative;

            ::v-deep .el-tabs {
                height: 100%;
                width: 100%;
                box-shadow: none;
                border: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                background: transparent;
            }

            ::v-deep .el-tabs__header {
                border-bottom: none;
                margin: 0;
            }

            ::v-deep .el-tabs__content {
                height: 1%;
                flex: 1;
                background: #fff;
                padding-top: 20px;
                min-height: 600px;
            }

            ::v-deep .el-tabs__header .is-active {
                background: #fff;
                font-size: 16px;
                font-weight: 400;
                color: #0824ce;
                border-radius: 6px 6px 0 0;
            }

            ::v-deep .el-tabs__item {
                border: none;
                height: 52px;
                line-height: 52px;
            }

            ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                border: none;
            }

            ::v-deep .el-tab-pane {
                height: 100%;
            }

            .update-content {
                height: 20px;
                position: absolute;
                top: 14px;
                right: 0;

                .update-title {
                    margin: 0 10px;
                    font-size: 14px;
                    cursor: pointer;

                    i {
                        color: #0824ce;
                        margin-right: 10px;
                    }

                    &:hover {
                        color: #1D33E1;
                    }
                }
            }
        }
    }
}

