
.contain_box {
  height: 100%;

  .head_title {
    height: 80px;
    display: flex;
    justify-content: space-between;

    .left_title {
      margin-left: 40px;
    }
  }

  .main_box {
    padding-top: 20px;
    margin-left: 40px;
  }

  .current {
    color: #20c997;
  }
}

.data-trend {
  margin-top: 20px;

  .data-menu-box {
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
  }

  ::v-deep.tooltip {
    height: 59px;
    border-radius: 2px;
    box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
    background: #fff;

    .tooltip-box {
      padding: 13px 20px 10px;
    }

    .tooltip-title {
      font-size: 12px;
      color: #999999;
    }

    .tooltip-title-right {
      float: right;
      padding-right: 10px;
      font-size: 16px;

      &.blue {
        color: #04a8ff;
      }

      &.purple {
        color: #857fdd;
      }
    }

    .tooltip-title-left {
      color: #333;
    }
  }
}

.data-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  text-align: center;

  .item {
    cursor: pointer;
    width: 50px;
    height: 30px;
    background: #f1f0fe;
    display: flex;
    justify-content: center;
    align-items: center;

    &.left {
      border-radius: 5px 0px 0px 5px;
    }

    &.right {
      border-radius: 0px 5px 5px 0px;
    }
  }

  .selected {
    color: #ffffff;
    background: #564ced;
  }
}
