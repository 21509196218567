
.contain_box {
    height: 100%;
}
.big-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.data-box {
    margin-top: 10px;
    width: 100%;
    height: 300px;
}
.comment-box {
    margin-bottom: 20px;
    ::v-deep & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .comment-list {
        padding: 0 20px;
        max-height: 400px;
        display: flex;
        flex-wrap: wrap;
        /*align-items: flex-start;*/
        .comment-item {
            width: calc(50% - 10px);
            display: flex;
            align-items: center;
            background: #f1f0fe;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 10px;
            &:nth-of-type(even) {
                margin-left: 20px;
            }
            .left {
                text-align: center;
                .iconfont {
                    color: #e8828b;
                    font-size: 30px;
                }
                .text {
                    font-size: 16px;
                }
            }
            .center {
                margin: 0 10px 0 20px;
                width: 1%;
                flex: 1;
                font-size: 16px;
            }
        }
    }
}

.no-data {
    height: 20px;
    margin: 20px 0;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
}
