
.fan-analysis {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 20px;
    .analysis-top{
        display: flex;
        border-bottom: 5px solid #EEEEEE;
        padding-bottom: 34px;
        margin: 0 30px;
        .analysis-left {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            border-right: 5px solid #EEEEEE;
            padding: 0 54px 0 24px;
            box-sizing: border-box;
            .gender-distribution {
                display: flex;
                flex-direction: column;
                height: 100%;
                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333;
                }
                .distribution-chart {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    height: 1%;
                    justify-content: center;
                }
            }
        }
        .analysis-right {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            .age-distribution {
                .title {
                    padding-left: 32px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333;
                }
            }
        }
    }
    .analysis-bottom{
        display: flex;
        flex-direction: column;
        padding: 0 34px;
        margin-top: 40px;
        .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            padding-right: 5px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            cursor: pointer;
            &.current {
                color: #0000FF;
            }
        }
        .content {
            flex: 1;
            overflow: auto;
            padding-right: 5px;
        }
        .content {
            flex: 1;
            overflow: auto;
            padding-right: 5px;
        }
        .area-distribution {
            flex: 1;
            display: flex;
            flex-direction: column;
            height: calc(100% - 66px);
            .area-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                .area-name {
                    width: 50px;
                    white-space: nowrap;
                    overflow: hidden;
                }
                ::v-deep .el-progress {
                    width: 80%;
                    padding: 0 10px;
                }
                ::v-deep .el-progress-bar__inner {
                    background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                }
            }
        }
    }
}

.sex-box {
    display: flex;
    width: 100%;
    .sex-item {
        text-align: center;
        /*min-width: 30px!important;*/
        & > .text {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 40px;
        }
        .bar {
            height: 12px;
            border-radius: 6px;
        }
        .bottom {
            margin-top: 40px;
            .iconfont {
                width: 30px;
                text-align: center;
                display: inline-block;
                font-size: 30px;
            }
        }
        &:last-child {
            text-align: right;
        }
        &:first-child {
            text-align: left;
        }
    }
}

.no-data {
    height: 20px;
    margin: 20px 0;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.big-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
